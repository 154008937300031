.proyects {
  padding: 7%;
  animation-name: animation-proyects;
  animation-duration: 2s;

  a {
    text-decoration: none;
  }

  &-col {
    height: 100%;
    margin: 50px 0 50px 0;
  }

  &-contenedor-img{
    height: 550px;
    width: 100%;
    padding: 0 7% 5% 7%;
  }

  &-mascara {
    & h2 {
      margin-top: 30px;
      color: #000;
      font-size: 22px;
    }
  }

  /*Estilos Base*/
  // &-contenedor-img {
  //   width: 100%;
  //   height: 100%;
  //   float: left;
  //   overflow: hidden;
  //   position: relative;
  //   text-align: center;
  // }

  // &-contenedor-img &-mascara {
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   overflow: hidden;
  //   top: 0;
  //   left: 0;
  //   justify-content: center;
  //   align-items: center;
  // }

  // &-contenedor-img img {
  //   display: block;
  //   position: relative;
  // }
  
  // &-contenedor-img h2 {
  //   text-transform: uppercase;
  //   color: #fff;
  //   text-align: center;
  //   position: relative;
  //   font-size: 17px;
  //   padding: 0px;
  //   // background: rgba(0, 0, 0, 0.7);
  //   margin: 155% 0 0 0;
  //   font-weight: 700;
  // }
  
  // &-contenedor-img-hover &-mascara {
  //   opacity: 1;
  //   // background-color: rgba(0,0,0, 0.3);
  //   // animation-name: animation-img;
  //   // animation-duration: 5s;
  //   // animation-iteration-count: infinite;
  // }

  // &-contenedor-img-hover h2 {
  //   // transform: translateX(-200px);/*Desplazamos a la izquierda*/
  //   opacity: 1;
  //   // animation-name: animation-txt;
  //   // animation-duration: 5s;
  //   // animation-iteration-count: infinite;
  // }

  @media only screen and (min-width: 900px){
    padding-top: 0;
    min-height: 78.9vh;
    width: 100%;

    &-row-container {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 100%;
      padding-top: 0;
      text-align: center;
    }

    &-col {
      margin-top: 0;
      padding: 0;
      height: 40vh;
      padding: 20px;
    }

    &-row-container:nth-child(1) {
      margin-bottom: 0;
    }

    &-contenedor-img{
      height: 600px;
      width: 250px;

      &:hover img {
        transform: scale(1.1);
        transition-duration: .5s;
      } 
    }

    &-mascara {
      & h2 {
        margin-top: 30px;
        color: #000;
        font-size: 22px;
      }
    }

    /*Estilos Base*/
    // &-contenedor-img {
    //   width: 100%;
    //   height: 100%;
    //   float: left;
    //   overflow: hidden;
    //   position: relative;
    //   text-align: center;
    // }

    // &-contenedor-img &-mascara {
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   overflow: hidden;
    //   top: 0;
    //   left: 0;
    //   justify-content: center;
    //   align-items: center;
    // }

    // &-contenedor-img img {
    //   display: block;
    //   position: relative;
    // }
    
    // &-contenedor-img h2 {
    //   text-transform: uppercase;
    //   color: #fff;
    //   text-align: center;
    //   position: relative;
    //   font-size: 18px;
    //   font-weight: 700;
    //   padding: 10px;
    //   // background: rgba(0, 0, 0, 0.7);
    //   margin: 85% 0 0 0;
    // }

    /*Ejemplo 1*/
    // &-contenedor-img-hover img {
    //   transition: all 0.2s linear;
    // }

    // &-contenedor-img-hover &-mascara {
    //   opacity: 1;
    //   // background-color: rgba(0,0,0, 0.3);
    //   // transition: all 0.3s ease-in-out;
    //   // nimation-name: none;
    // }

    // &-contenedor-img-hover h2 {
    //   // transform: translateX(-200px);/*Desplazamos a la izquierda*/
    //   opacity: 1;
    //   // transition: all 0.7s ease-in-out;
    //   // animation-name: none;
    // }

    // &-contenedor-img-hover:hover img {
    //   transform: scale(1.1);/*Damos un ligero zoom a la imagen*/
    // }

    // &-contenedor-img-hover:hover &-mascara {
    //   opacity: 1;
    // }

    // &-contenedor-img-hover:hover h2 {
    //   opacity: 1;
    //   // transform: translateX(0px);/*Regresamos a las posiciones originales*/
    // }
  }
}

@keyframes animation-proyects {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// @keyframes animation-img {
//   from {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// }

// @keyframes animation-txt {
//   from {
//     transform: translateX(-200px);
//     opacity: 0;
//   }
//   50% {
//     transform: translateX(0px);
//     opacity: 1;
//   }
//   to {
//     transform: translateX(200px);
//     opacity: 0;
//   }
// }