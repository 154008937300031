.about-us-real-state {
  animation-name: animation-abutUs;
  animation-duration: 2s;
  padding-top: 30px;
  padding-bottom: 50px;

  &-header {
    margin-top: 50px;
  }

  &-description {
    margin: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 900px){  
    &-header {
      margin-top: 75px;
    }

    &-description {
      margin: 75px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@keyframes animation-abutUs {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}