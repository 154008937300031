.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%;
  padding: 0 5%;
  margin: 0;
  box-sizing: border-box;
  animation-name: animation-footer;
  animation-duration: 2s;

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-left {
    padding: 10px 0 40px 0;
  }

  &-right {
    border-left: 1px solid gray;
  }

  &-img {
    width: 130px !important;
  }

  &-know-text {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
  }

  @media only screen and (min-width: 900px){ 
    &-img {
      width: 200px !important;
    }

    &-row__ctn{
      width: 100%;
    }

    &-left {
      border-right: 1px solid gray;
      padding: 0;
    }

    &-right {
      border-left: 1px solid gray;
    }
    
  }

  &-contact {
    &-means__container {
      padding-top: 0px;
    }
  
    &-option {
      display: flex;
      text-decoration: none;
      color: #fff;
      margin-bottom: 10px;
      font-size: 12px;
      text-align: left;
    }
  
    &-option svg{
      min-width: 75px;
      margin-top: 0px;
      margin-left: auto;
      margin-right: auto;
    }
  
    @media only screen and (min-width: 900px){  
      padding: 0 5%;

      &-info {
        display: flex;
        justify-content: flex-end;
      }

      &-means {
        border-top: none;
      }
  
      &-means__container {
        padding-top: 17px;
        display: flex;
      }
    
      &-option {
        display: flex;
        text-decoration: none;
        color: #fff;
        margin-bottom: 10px;
  
        &:hover {
          color: #fff;
        }
      }
    
      &-option svg{
        min-width: 75px;
        margin-top: 5px;
        margin-left: 0;
      }
    }
  }  
}

@keyframes animation-footer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}