.about {
  min-height: 72vh;
  animation-name: animation-about;
  animation-duration: 2s;
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  &-text {
    padding: 35px 25px;
    border-bottom: 1px solid gray;
    h3{
      margin-bottom: 35px;
      font-weight: 700;
      text-transform: uppercase;
    }

    h4{
      margin-bottom: 25px;
      font-weight: 700;
    }
   }

  @media only screen and (min-width: 900px){  
   &-text {
    h1 {
      margin-bottom: 100px;
    }
   }
  }
}

@keyframes animation-about {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}