.not-found {
  min-height: 72vh;
  animation-name: animation-about;
  animation-duration: 2s;
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &-text {
    padding: 35px 25px;
    h1{
      margin-bottom: 35px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
}

@keyframes animation-about {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}