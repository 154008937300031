.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 7%;
  box-sizing: border-box;
  animation-name: animation-contact;
  animation-duration: 2s;

  &-site__txt__container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }

  &-site__txt__container__logo {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 50px;
  }

  &-site__txt__container {
    display: block;
    justify-content: left;
    text-align: left;
    font-size: 12px;
    line-height: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid gray;;
  }

  &-logo {
    width: 150px;
  }
  
  &-site__txt {
    font-weight: bold;
    font-size: 1.2rem;
  }

  &-means {
    border-top: 1px solid gray;
  }

  &-means__container {
    padding-top: 50px;
  }

  &-option {
    display: flex;
    text-decoration: none;
    color: #000;
    margin-bottom: 10px;
  }

  &-option svg{
    min-width: 75px;
    margin-top: 5px;
    margin-left: 11%;
  }

  @media only screen and (min-width: 900px){
  
    &-means {
      border-left: 1px solid gray;
      border-top: none;
    }

    &-means__container {
      padding-top: 0;
    }
  
    &-option {
      display: flex;
      text-decoration: none;
      color: #000;
      margin-bottom: 10px;

      &:hover {
        color: #000;
      }
    }
  
    &-option svg{
      min-width: 75px;
      margin-top: 5px;
      margin-left: 0;
    }
  }
}

@keyframes animation-contact {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}