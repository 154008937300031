.proyect {
  animation-name: animation-proyects;
  animation-duration: 2s;
  padding-top: 30px;
  padding-bottom: 50px;

  &-header__img h1{
    text-align: center;
    margin-bottom: 35px;
  }

  &-presentation__txt__container {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 40px 0;
  }

  &-description__txt__container {
    height: 100%;
    padding: 0 15px 0 15px;
    margin: 40px 0;
  }

  &-img-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 400px;
    width: 300px;
    margin: 0 auto;
  }

  &-resume__txt__container {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin: 40px 0;
  }

  @media only screen and (min-width: 900px){
    padding-top: 60px;
    padding-bottom: 150px;

    &-header__img h1{
      text-align: left;
      margin-bottom: 35px;
    }
  
    &-presentation__txt__container {
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
    }

    &-img-ctn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      height: 300px;
      width: 200px;
      margin: 0 auto;
    }
  
    &-description__txt__container {
      height: 100%;
      padding: 0 15px 200px 15px;
    }
  
    &-resume__txt__container {
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;
    }
  }
}

@keyframes animation-proyect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}