.home {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 7%;
  box-sizing: border-box;
  animation-name: animation-home;
  animation-duration: 2s;
  background: transparent;

  &-row-container {
    height: 100%;
    margin: 50px 0 50px 0;
  }

  &-row-container:nth-child(1) {
    margin-bottom: 20px;
  }

  &-contenedor-img{
    height: 550px;
    width: 100%;
    padding: 0 7% 5% 7%;
  }

  &-mascara {
    & h2 {
      margin-top: 30px;
      color: #fff;
    }
  }

  /*Estilos Base*/
  // &-contenedor-img {
  //   width: 100%;
  //   height: 100%;
  //   float: left;
  //   overflow: hidden;
  //   position: relative;
  //   text-align: center;
  // }

  // &-contenedor-img &-mascara {
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   overflow: hidden;
  //   top: 0;
  //   left: 0;
  //   justify-content: center;
  //   align-items: center;
  // }

  // &-contenedor-img img {
  //   display: block;
  //   position: relative;
  // }
  
  // &-contenedor-img h2 {
  //   text-transform: uppercase;
  //   color: #000;
  //   text-align: center;
  //   position: relative;
  //   font-size: 16px;
  //   padding: 10px;
  //   background: transparent;
  //   margin: 105% 0 0 0;
  // }
  
  // &-contenedor-img-hover &-mascara {
  //   opacity: 1;
  //   background-color: transparent;
  //   // animation-name: animation-img;
  //   // animation-duration: 5s;
  //   // animation-iteration-count: infinite;
  // }

  // &-contenedor-img-hover h2 {
  //   // transform: translateX(-200px);/*Desplazamos a la izquierda*/
  //   opacity: 1;
  //   // animation-name: animation-txt;
  //   // animation-duration: 5s;
  //   // animation-iteration-count: infinite;
  // }

  @media only screen and (min-width: 900px){
    min-height: 78.9vh;
    height: 78.9vh;
    max-height: 78.9vh;
    width: 100%;
    padding: 0;

    &-row-container {
      height: 70vh;
      width: 20vw;
      margin: 50px;
    }

    &-row-container:nth-child(1) {
      margin-bottom: 0;
      object-fit:contain;
    }

    &-contenedor-img{
      height: 600px;
      width: 250px;

      &:hover img {
        transform: scale(1.1);
        transition-duration: .5s;
      } 
    }

    &-mascara {
      & h2 {
        margin-top: 30px;
        color: #fff;
      }
    }

    /*Estilos Base*/
    // &-contenedor-img {
    //   width: 100%;
    //   height: 100%;
    //   float: left;
    //   overflow: hidden;
    //   position: relative;
    //   text-align: center;
    // }

    // &-contenedor-img &-mascara {
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   overflow: hidden;
    //   top: 0;
    //   left: 0;
    //   justify-content: center;
    //   align-items: center;
    // }

    // &-contenedor-img img {
    //   display: block;
    //   position: relative;
    // }
    
    // &-contenedor-img h2 {
    //   text-transform: uppercase;
    //   color: #000;
    //   text-align: center;
    //   position: relative;
    //   font-size: 18px;
    //   padding: 10px;
    //   background: transparent;
    //   margin: calc(70vh - 30%) 0 0 -45%;
    // }

    /*Ejemplo 1*/
    // &-contenedor-img-hover img {
    //   transition: all 0.2s linear;
    // }

    // &-contenedor-img-hover &-mascara {
    //   opacity: 1;
    //   background-color: transparent;
    //   // transition: all 0.3s ease-in-out;
    //   // animation-name: none;
    // }

    // &-contenedor-img-hover h2 {
    //   // transform: translateX(-200px);/*Desplazamos a la izquierda*/
    //   opacity: 1;
    //   // transition: all 0.7s ease-in-out;
    //   // animation-name: none;
    // }

    // &-contenedor-img-hover:hover img {
    //   transform: scale(1.1);/*Damos un ligero zoom a la imagen*/
    // }

    // &-contenedor-img-hover:hover &-mascara {
    //   opacity: 1;
    // }

    // &-contenedor-img-hover:hover h2 {
    //   opacity: 1;
    //   transform: translateX(0px);/*Regresamos a las posiciones originales*/
    // }
  }
}

@keyframes animation-home {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// @keyframes animation-img {
//   from {
//     opacity: 0;
//   }
//   50% {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// }

// @keyframes animation-txt {
//   from {
//     transform: translateX(-200px);
//     opacity: 0;
//   }
//   50% {
//     transform: translateX(0px);
//     opacity: 1;
//   }
//   to {
//     transform: translateX(200px);
//     opacity: 0;
//   }
// }

